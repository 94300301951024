* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100%;
  font-family: 'Nunito', sans-serif;
  background-color: #f4f5f7;
}

.App {
  text-align: center;
}
